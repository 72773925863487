import useToggleState from '@components/_hooks/useToggleState';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { Span } from '../Reusables/Typography/P';

const sx = {
    fontSize: '14px'
}

const PriceInfoTag = ({className = "", title = "", children}) => {

    const {open, handleToggle, setOpen} = useToggleState();

    return (
        <Tooltip 
            arrow
            open={open}
            title={<Span sx={sx}>{title}</Span>}
            onClick={handleToggle} 
            onMouseEnter={handleToggle} 
            onMouseLeave={() => setOpen(false)} 
        >
            <InfoIcon className="pointer" color="primary"/>
        </Tooltip>
    )
}

export default PriceInfoTag